<template>
  <form-modal v-bind="bindChain.form" v-on="onChain.form">
    <v-form :ref="formRef">
      <v-container>
        <v-autocomplete
          :items="ramos"
          v-model="form.ramo"
          :loading="loadingRamos"
          :disabled="!!ramoId"
          item-text="ramo_descripcion"
          item-value="_id"
          item-disabled="eliminado"
          label="Seleccione un ramo"
          :rules="[(v) => !!v || 'Debe seleccionar un ramo.']"
        ></v-autocomplete>

        <v-row no-gutters>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="form.uso_producto_descripcion"
              class="mr-1"
              label="Descripción"
              :rules="[(v) => !!v || 'El campo descripción es requerido.']"
              placeholder="Escriba una descripción para el uso del producto..."
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.codigo"
              class="ml-1"
              label="Código"
              :rules="[(v) => !!v || 'El campo código es requerido.']"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </form-modal>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";
import { mapActions } from "vuex";
export default {
  name: "UsoProductoModalForm",
  mixins: [FormMixin],
  props: {
    ramoId: {
      type: String,
      default: "",
      description: "Id del ramo seleccionado (solo si aplica)",
    },
  },
  data: () => ({
    defaultTitle: "Formulario de uso del producto",
    form: {
      uso_producto_descripcion: "",
      ramo: "",
      codigo: "",
    },
    loadingRamos: false,
    ramos: [],
  }),
  methods: {
    ...mapActions("NewProduct", ["getRamos"]),

    setup() {
      this.loadingRamos = true;
      if (this.ramoId) {
        this.form.ramo = this.ramoId;
      }
      this.getRamos()
        .then((response) => (this.ramos = response))
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loadingRamos = false;
        });
    },
  },
};
</script>
