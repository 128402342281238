<template>
  <v-container>
    <v-card>
      <v-data-table v-bind="bind.table" :search="search">
        <template #top>
          <table-top v-bind="bindChain.tableTop" v-on="onChain.tableTop" />
        </template>

        <template #item.eliminado="{ item }">
          <v-chip
            class="ma-2"
            small
            :color="item.eliminado ? 'grey lighten-2' : 'primary'"
          >
            <v-icon x-small class="mr-1">mdi-checkbox-blank-circle</v-icon>
            {{ item.eliminado ? labels.disabled : labels.enabled }}
          </v-chip>
        </template>

        <template #item.actions="{ item }">
          <v-tooltip left color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                x-small
                text
                fab
                @click="activateItem(item)"
                v-on="on"
                v-bind="attrs"
                :disabled="!item[itemDisabled]"
              >
                <v-icon small> {{ icons.activate }} </v-icon>
              </v-btn>
            </template>
            <span>Activar</span>
          </v-tooltip>

          <table-actions
            v-bind="bindChain.tableActions"
            v-on="onChain.tableActions(item)"
            :deleteDisabled="item[itemDisabled]"
          />
        </template>
      </v-data-table>
    </v-card>

    <Form v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageMixin from "@/Mixins/SetPageMixin.js";
import Form from "@/components/Forms/cruds/usoProducto.vue";
export default {
  name: "ProductUseMaintainer",
  mixins: [PageMixin],
  data: () => ({
    branches: [],
    loadingBranches: false,
    itemId: "uso_producto",
  }),
  components: {
    Form,
  },
  computed: {
    ...mapGetters("NewProduct", ["getProductUse"]),
    items() {
      return this.getProductUse;
    },

    headers() {
      return [
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "uso_producto_descripcion",
        },
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "codigo",
        },
        {
          text: "Ramo",
          align: "start",
          sortable: true,
          value: "ramo_descripcion",
        },
        {
          text: "Estado",
          align: "center",
          sortable: true,
          value: "eliminado",
        },
        { text: "Actions", value: "actions", align: "end", sortable: false },
      ];
    },
  },
  methods: {
    ...mapActions("NewProduct", [
      "CreateProductUse",
      "UpdateProductUse",
      "DeleteProductUse",
      "ActivateProductUse",
      "REQUEST_PRODUCT_USE",
    ]),

    setup() {
      this.loading = true;
      this.REQUEST_PRODUCT_USE()
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveMethod() {
      return this.CreateProductUse;
    },
    updateMethod() {
      return this.UpdateProductUse;
    },
    deleteMethod() {
      return this.DeleteProductUse;
    },
    activateMethod() {
      return this.ActivateProductUse;
    },
  },
};
</script>
